﻿#home-stage{
    position: relative;
    #stage {
        position: relative;
        height: 100vh;
        width: 100%;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        display: flex;
        video {
            /*position: absolute;
            left: 50%;
            top: 50%;
            min-height: 100%;
            min-width: 100%;
            transform: translate(-50%, -50%);
            z-index: 0;*/
        }
    }
    #video-stage{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: -1;
        #video-player{
            height: 100vh;
        }
    }
}

#stage #stage-wrapper {
    width: 100%;
    z-index: 2;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
}