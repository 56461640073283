﻿#footer {
    padding-bottom: 4%;

    .hr {
        margin-bottom: 45px;
    }

    .footer-list {
        padding-bottom: 15px;
        ul {
            list-style: none;
            list-style-position: inside;
            padding-left: 0;
            padding-bottom: 20px;

            &.categorias li:first-child a {
                color: #5C5C5C;
            }

            li a{
                font-size: 18px;
                line-height: 24px;
                font-weight: 300;
                color: #8E8E8E;
                text-decoration: none;
                &:hover{ text-decoration: underline;}
            }
        }

        p {
            padding-bottom: 25px;
            font-size: 18px;
            line-height: 24px;
            font-weight: 300;
            color: #8E8E8E;
            text-decoration: none;
            a{
                color: #1A963A;
                text-decoration: none;
                &:hover{ text-decoration: underline;}
            }
        }
    }

    
    .c9 .c4 {
        @media only screen and (max-width:560px) {
            width: 100%;
        }
    }

    .c2, .c4 {
        @media handheld, only screen and (max-width: 768px), only screen and (max-device-width: 768px) and (orientation:portrait) {
            width: 50%;

            &:nth-child(2n) {
                clear: left;
            }
        }
    }
}

#logo-footer {
    display: inline-block;
    float: right;
    width: 83px;
    height: 83px;
    text-indent: -1000px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #1A963A;
    background-image: url('../assets/img/logo.png');
    background-size: 69px 46px;
    &:hover{ opacity: .93;}
    @media only screen and (max-width:560px) {
        float: none;
    }
}

#ilogica{
    text-align: right;
    padding-top: 2%;
    @media only screen and (max-width:560px) {
        text-align: left;
    }
    a{
        display: inline-block;
        width: 127px;
        height: 23px;
        background-size: 127px 23px;
        background-image: url('../assets/img/ilogica@2x.png');
        -o-transition:.5s;
        -ms-transition:.5s;
        -moz-transition:.5s;
        -webkit-transition:.5s;
        transition:.5s;
        &:hover{ opacity: .8;}
    }
}
