#aco-buscador {
   .dropdown {
      display: none;
    }
  .stage-buscador {
    border: 5px solid #1a963a;
    border-radius: 3px;
    font-size: 0;
    position: relative;
    background-color: #fff;
    opacity: .9;   
    .buscador {
      width: 50%;
      display: inline-block;
      position: relative;
      input[type='text'] {
        padding: 15px 20px;
        border: none;
        width: 100%;
        outline:none;
      }
      &.busca-lugar{
        @media only screen and (max-width:560px) {
          width: 100%;
        }
      }
      &.busca-tipo{
        @media only screen and (max-width:560px) {
          display: none;
        }
      }
      .tipo-vivienda {
        position: absolute;
        z-index: 3;
        top: 100%;
        left: 0;
        background-color: #fff;
        width: 100%;
        border: 1px solid rgba(153, 153, 153, .58);
        input {
          position: absolute;
          z-index: 4;
          opacity: 0;
        }
        label {
          width: 100%;
          display: block;
          padding: 15px 20px;
          cursor: pointer;
          -o-transition:.5s;
          -ms-transition:.5s;
          -moz-transition:.5s;
          -webkit-transition:.5s;
          transition:.5s;
          &:hover {
            background-color: #ddebdb;
          }
        }
      }
      &:first-child {
        border-radius: 3px 0 0 3px;
        border-right: 1px solid rgba(153, 153, 153, 0.58);
      }
      &:last-child {
        border-radius: 0 3px 3px 0;
      }
    }
    &:hover {
      opacity: 1;
    }
    .stage-lupa {
      position: absolute;
      right: 20px;
      top: 18px;
      display: inline-block;
      z-index: 3;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../assets/img/lupa.png');
      background-size: 20px;
    }
  }
  .resultados-buscador {
    background-color: #FFFFFF;
    border-radius: 0px 0px 2px 2px;
    padding: 0px 15px 15px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 50vh;
    @media only screen and (max-width:560px) {
      max-height: 30vh;
    }
    .fila {
      margin-bottom: 10px;
      .busqueda-valor{
        padding: 0;
      }
    }
    p, li a {
      font-weight: 300;
      line-height: 28px;
    }
    p {
      font-size: 16px;
      color: #7E7E7E;
    }
    ul {
      padding-left: 0;
      list-style-position: inside;
      list-style: none;
      li a {
        display: block;
        text-decoration: none;
        font-size: 18px;
        color: #5C5C5C;
        padding: 0 20px;
        &:hover{
          background-color: #ddebdb;
        }
        .separador{ font-size: 12px;}
      }
    }
  }
}
