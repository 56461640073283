﻿#conocenos-widget {
    text-align: center;
    background-image: url('../assets/img/conocenos-home.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center top;
    background-size: 100% 100%;
    padding: 19% 0 6%;
    @media handheld, only screen and (max-width: 768px), only screen and (max-device-width: 768px) and (orientation:portrait) {
        background-size: cover;
        padding: 30px 0;
        background-attachment: inherit;
    }
    h2 {
        color: #FEFEFE;
        font-size: 64px;
        line-height: 1;
        font-weight: 300;
        padding-bottom: 25px;
        @media only screen and (max-width:560px) {
           font-size: 36px;
           line-height: 42px; 
        }
    }
    .button{
        padding-left: 35px;
        padding-right: 35px;
        &:hover{ 
            background-color: #1a963a; color: #fff;
        }
    }
    ul {
        list-style-position: inside;
        padding-bottom: 15px;
        padding-left: 0;
        li {
            display: inline-block;
            list-style: none;
            &::after {
                content: '·';
                font-size: 24px;
                font-weight: 300;
                color: #FEFEFE;
            }
            @media only screen and (max-width:560px) {
                display: block;
                font-size: 22px;
                line-height: 36px;
                &::after {
                    content: '';
                }
            }
            &:last-child::after {
                content: '';
            }
            a {
                font-size: 24px;
                line-height: 33px;
                font-weight: 300;
                color: #FEFEFE;
                text-decoration: none;
                margin: 0 15px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
