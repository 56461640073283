﻿.slick-slider{ margin-bottom: 0;}
.slick-loading .slick-list{ background: none;}

.slide {
    opacity: 0.8;
    p {
        font-size: 18px; line-height: 24px; color: #8E8E8E; font-weight: 300; padding-top: 20px; padding-bottom: 20px; min-height: 10px; opacity: 0;
        &:last-child{
            padding-bottom: 0;
        }
    }
    &.slick-active {
        opacity: 1;
        p{
            opacity: 1;
        }
    }
}

.slick-prev {
    &, &:hover, &:focus {
        left: 25px; background-image: url('../assets/img/left-arrow.png');
    }

    &::before {
        content: '';
    }
    
}
.slick-next {
    &, &:hover, &:focus {
        right: 25px; background-image: url('../assets/img/right-arrow.png');
    }

    &::before {
        content: '';
    }
}
.slick-next, .slick-prev {
    &, &:focus, &:hover {
        background-color: rgba(0,183,39,.7); background-repeat: no-repeat; width: 42px; height: 42px; border-radius: 50%; background-position: center;
    }
    &, &:focus {
        opacity: 0.7;
    }
    &:hover {
        opacity: 1;
    }
    @media handheld, only screen and (max-width: 768px), only screen and (max-device-width: 768px) and (orientation:portrait){
        top: 40%;
    }
}