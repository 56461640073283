﻿#filtros-busqueda, .resumen-busqueda {
  ul {
    list-style: none;
    list-style-position: inside;
    padding-left: 0;
    li {
      display: inline-block;
      a, button {
        font-weight: 300;
        text-decoration: none;
        font-size: 16px;
        line-height: 22px;
        display: inline-block;
        &:focus {
          outline: none;
        }
      }
      button {
        background-color: transparent;
        border: 0;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

#filtros-busqueda {
  background-color: rgb(255, 255, 255);
  position: relative;  
  ul{
    li {
      margin-right: 4%;
      padding-top: 0px;
      padding-bottom: 0px;
      position: relative;
      @media only screen and (max-width:480px){
          padding-bottom: 0;
      }
      a, button {
        color: #8E8E8E;
        padding: 12px 10px;
        cursor: pointer;
        font-size: 16px;
        line-height: 22px;
        display: inline-block;
        font-weight: 300;
        &:hover {
          padding: 12px 10px 9px;
          border-bottom: 3px solid #8fc807;
        }
        &.checked {
          color: #414141;
          padding: 7px 10px 4px;
          border-bottom: 3px solid #8fc807;
        }
        @media only screen and (max-width:480px){
          padding: 0 10px;
        }
      }
    }
  }
  .barra-filtros{
    position: relative;
    ul{
      @media only screen and (max-width:480px){
        li:nth-child(4), li:nth-child(5){
          display: none;
        }
      } 
      @media only screen and (max-width:400px){
        li:nth-child(3){
          display: none;
        }
      } 
    } 
  }
}

.resumen-busqueda {
  padding-top: 15px;
  padding-bottom: 15px;
  a, button {
    color: #5C5C5C;
  }
  ul{
    display: inline-block;
    li {
      a:hover, a.activo {
        text-decoration: underline;
      }
      &::after {
        content: '·';
      }
      &:last-child::after {
        content: '';
      }
    }
  }
}

#grid-switch{
  display: inline-block;
  float: right;
  @media only screen and (max-width:560px){
    display: none;
  }
  a{
    display: inline-block;
    text-decoration: none;
    border-radius: 2px;
    margin-left: 2px;
    height: 27px;
    width: 27px;
    background-size: 21px 19px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: .3;
    &.active{
      opacity: .6;
    }
    &#filas{
      background-image: url('../assets/icon/icono-lista.png');
    }
    &#matriz{
      background-image: url('../assets/icon/icono-bloques.png');
    }
  }
}