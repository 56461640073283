﻿#financiamiento-widget {
    background-color: #1A963A;
    display: block;
    padding: 5% 0;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    background-image: url('../assets/img/financiamiento-widget-aconcagua.jpg');
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
    &:hover{
        .button{
            background-color: #1a963a;
            color: #fff;
        }
    }
    @media only screen and (max-width:560px) {
        padding: 30px 0;
    }
    h2 {
        color: #FFFFFF;
    }

    h3 {
        font-size: 26px;
        line-height: 36px;
        font-weight: 300;
        color: #FEFEFE;
        padding-bottom: 30px;
    }

    @media handheld, only screen and (max-width: 768px), only screen and (max-device-width: 768px) and (orientation:portrait) {
        background-size: cover;
    }
}
