﻿@import (inline) '../../../node_modules/normalize.css/normalize.css';
@import (css) url(//fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300italic,700);
@import (inline) '../../../lib/gridiculous/gridiculous.css';
@import 'header.less';
@import 'footer.less';
@import 'breadcrumbs.less';
@import 'opciones.less';
@import 'form.less';
@import '../resultados/modal-filtros.less';
@import '../resultados/resumen-filtros.less';

body {
    font-family: 'Open Sans Condensed', sans-serif;
}
h1, h2, h3, h4, h5, h6, p, ul, ol, hr{ margin: 0;}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

video::-webkit-media-controls {
    display:none !important;
}

.video-wrapper iframe {
    max-width: 100%;
}

.wrapper {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

.reset {
    clear: both;
}

.none{
    display: none;
}

.clear {
    &::before, &::after {
        content: '';
        display: table;
        clear: both;
    }
}

.table-row{
    display: table;
    width: 100%;
}
.table-cell{
    display: table-cell;
    @media only screen and (max-width: 768px) {
        display: block;
    }
}

.o5 {
    width: 20%;
}
.o4{
    width: 25%;
}
.o3 {
    width: 33.33%;
}
.o2 {
    width: 50%;
}
.opaco{
    opacity: 1;
}
.button {
    font-weight: 300;
    font-size: 20px;
    line-height: 27px;
    border-radius: 2px;
    text-decoration: none;
    padding: 9px 24px;
    display: inline-block;
    border: none;
    max-width: 100%;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
    @media only screen and (max-width:560px) {
        padding: 9px 5px;
    }
}

.white.button {
    background-color: #FEFEFE;
    color: #1A963A;
}

.green.button {
    background-color: #8DC703;
    color: #FEFEFE;
}

.grey.button{
    color: #5C5C5C;
    background-color: #E9E9E9;
    &:hover{
        background-color: #ccc;
        color: #8E8E8E;
    }
}

.simple-button {
    font-size: 18px;
    line-height: 24px;
    color: #ACACAC;
    background-color: #E8E8E8;
    border-radius: 2px;
    display: inline-block;
    padding: 3px 10px;
    text-decoration: none;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
    &:hover{
        background-color: #ccc;
        color: #8E8E8E;
    }
}

.green.simple-button {
    color: #FEFEFE;
    background-color: #8DC703;
}

.tooltip {
    font-size: 10px;
    line-height: 15px;
    color: #5c5c5c;
    border: 1px solid #5c5c5c;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    bottom: 3px;
    cursor: pointer;
    &:hover{
        background-color: #5c5c5c;
        color: #f6f6f6;
    }
}

.tooltipster-style {
    border-radius: 0px; 
    border: none;
    background: #9b9b9b;
    color: #fff;
    max-width: 400px;
}
.tooltipster-style .tooltipster-content {
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    padding: 8px 10px;
}

.buscador {
    font-size: 18px;
    line-height: 24px;
    color: #767676;
    font-weight: 300;
}

.vermas {
    float: right;
    display: inline-block;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    color: #5C5C5C;
    &:hover{
        text-decoration: none;
    }
}

.acordeon{
    bold{ font-weight: 700;}
    a{
        text-decoration: none;
        color: #1A963A;
        font-weight: 300;
        font-size: 18px;
        line-height: 1.6;
        display: inline-block;
    }
    p {
        padding-top: 10px;
        font-size: 18px;
        line-height: 24px;
        color: #878787;
        padding-bottom: 15px;
    }
    ul{
        padding-bottom: 17px;
        padding-left: 0;
        list-style-position: inside;
        li{
            font-size: 18px;
            line-height: 24px;
            color: #878787;
        }
    }
    .acordeon-content{
        display: none;
    }
}
.hr {
    height: 1px;
    border-top: 1px solid rgba(92,92,92,0.30);
}

.title h2 {
    color: #8E8E8E;
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 3%;
}

.ultimo {
    float: right;
}

.opaco {
    opacity: 1 !important;
}

.none {
    display: none !important;
}

.iblock {
    display: inline-block !important;
}

.block {
    display: block;
}

.gradiente { /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}

.gradiente-single { /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,0c3411+100&0+0,0.65+100 */
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(12,52,17,0.65) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(12,52,17,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(12,52,17,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a60c3411',GradientType=0 ); /* IE6-9 */
}

h1, h2, h3, h4, h5, h6, p, ul, hr {
    margin: 0;
}

@media handheld, only screen and (max-width: 768px), only screen and (max-device-width: 960px) and (orientation:portrait) {
    .c1, .c2, .c3, .c4, .c5, .c6, .c7, .c8, .c9, .c10, .c11, .c12, .row .row .c1, .row .row .c2, .row .row .c3, .row .row .c4, .row .row .c5, .row .row .c6, .row .row .c7, .row .row .c8, .row .row .c9, .row .row .c10, .row .row .c11, .row .row .c12 {
        margin-bottom: 0px;
    }
}

select {
    -webkit-appearance: button;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
    -webkit-padding-end: 20px;
    -webkit-padding-start: 2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -moz-appearance: none;
    background-color: #ffffff;
    background-repeat: no-repeat;
    font-size: inherit;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 240px;
}

::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.texto-enriquecido{ padding-top: 20px;}
.texto-enriquecido h3{ font-size: 36px; line-height: 49px; color: #5C5C5C; font-weight: 300;}
.texto-enriquecido ul, .texto-enriquecido ol{ padding-left: 15px; }
.texto-enriquecido p, .texto-enriquecido li p{ color: #5C5C5C; font-size: 18px; line-height: 28px; font-weight: 300;}
.texto-enriquecido p{ padding-bottom: 20px;}
.texto-enriquecido li h5{ padding-bottom: 0; font-size: 20px; line-height: 28px; font-weight: 300; color: #5C5C5C;}
.texto-enriquecido p a, .texto-enriquecido li p a{ color: #5C5C5C;}
.texto-enriquecido li a{ 
    color: #1A963A; 
    text-decoration: none;
    &:hover{ text-decoration: underline;}
}
.texto-enriquecido p a:hover, .texto-enriquecido li p a:hover{ text-decoration: none;}
.texto-enriquecido ul li{ list-style: disc; }
.texto-enriquecido ul ul{ 
    padding-bottom: 20px;
    li{ list-style: disc;}
}
