﻿.proyectos-list {
    .simple-button {
        float: right;
        margin-top: 25px;
    }

    @media handheld, only screen and (max-width: 768px), only screen and (max-device-width: 768px) and (orientation:portrait) {
        .build {
            margin-bottom: 15px;
        }

        .c6 {
            width: 50%;
        }

        .c4 {
            width: 33%;
        }
    }
}

.proyectos-post {
    display: block;
    text-decoration: none;
    position: relative;
    margin-bottom: 15px;
    border-radius: 2px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
    &:hover{
        .button{ background-color: #1a963a;}
        .proyecto-data {
            background-color: rgba(254,254,254,1);
        }
    }
    @media only screen and (max-width:560px) {
        overflow-x: hidden;
        .proyecto-principal{
            height: 220px;
            width: auto;
            max-width: inherit;
        }
    }
    h3 {
        font-size: 26px;
        line-height: 28px;
        color: #414141;
        font-weight: 300;
    }
    h4 {
        font-size: 20px;
        line-height: 32px;
        color: #5C5C5C;
        font-weight: 300;
        .separador{ font-size: 15px;}
    }
    img{
        border-radius: 2px;
    }
    .button {
        display: block;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        @media only screen and (max-width:768px) {
            max-width: 150px;
            float: right;
        }
    }
}

@media only screen and (max-width:768px) {
    .row .proyecto-head .row .c10 {
        width: 100%;
    }

    .row .proyecto-head .row .c2 {
        display: none;
    }
}

.proyecto-principal {
    width: 100%;
}

.proyecto-head {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 15px 20px;
}

.proyecto-data {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: rgba(254,254,254,0.85);
    z-index: 2;
    padding: 15px 20px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
    border-radius: 0px 0px 2px 2px;
}

.proyecto-logo {
    width: 70px;
    margin-left: 0;
    margin-right: 0;
}

.c6 .data-proyecto {
    width: 50%;
    float: left;
    min-width: 200px;
    @media only screen and (max-width:768px) {
        width: 100%;
    }
}
