#breadcrumbs{
	padding-top: 2.5%;
	ul{
		list-style: none;
		list-style-position: inside;
		padding-left: 0;
		padding-top: 4%;
		padding-bottom: 4%;
		li{
			display: inline-block;
			margin-right: 10px;
			&::after {
			    content: '›';
			}
			&:last-child::after {
			    content: '';
			    margin-right: 0;
			}
			a{
				display: inline-block;
				font-size: 18px;
				line-height: 24px;
				color: #5C5C5C;
				font-weight: 300;
				text-decoration: none;
				margin-right: 10px;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
}