.checkbox-filtro {
    position: relative;
    padding-left: 35px;
    margin-bottom: 8px;

    input {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -2;
        opacity: 0;
    }

    .fakecheck {
        display: inline-block;
        border-radius: 3px;
        border: 3px solid #8DC703;
        height: 24px;
        width: 24px;
        margin-right: 10px;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
    }
    .fakelabel {
        font-size: 18px;
        line-height: 24px;
        color: #888888;
        font-weight: 300;
        display: inline-block;
        a{
            color: #888888;
            &:hover{ text-decoration: none;}
        }
    }

    input:checked + label .fakecheck, input:checked + span + label .fakecheck {
        background-color: #8DC703;
        box-shadow: inset 0 0 3px #fff;
    }
}