.opciones {
    display: block;
    border: 3px solid #8DC703;
    border-radius: 3px;
    font-size: 0;
    position: relative;

    .opcion {
        font-size: 18px;
        line-height: 24px;
        font-weight: 300;
        color: #888888;
        padding: 9px 2px;
        display: inline-block;
        text-align: center;
        float: left;
        cursor: pointer;
        background-color: #F6F6F6;
        border: 1px solid #fff;
        border-right: none;

        &:first-child {
            border-left: none;
        }

        &:hover {
            background-color: #ABD24C;
            color: #fff;
        }
        @media only screen and (max-width:480px){
            padding: 5px 2px;
        }
    }

    input[type='radio']:checked + .opcion {
        background-color: #ABD24C;
        color: #fff;
    }

    input[type='radio'] {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }
}