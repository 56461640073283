﻿.no-scroll #header{
    position: static !important;
}

#header {
    background-color: #fff;
    opacity: .92;
    position: fixed;
    z-index: 10;
    width: 100%;
    padding-top: 11px;
    top: 0;
    left: 0;
    font-size: 0;
    border-bottom: 2px solid #f0f0f0;
    #main-menu{
        padding-bottom: 0px;
    }
    &.fixed{
        position: fixed;
    }
    @media only screen and (max-width:768px) {
        &:not(.home){
            &.below{
                position: fixed;
            }
        }
    }
}

@media only screen and (max-width:768px) {
body:not(.homepage) #header{ position: relative;}
}

body.resultados-page{
    #header{ 
        position: relative;
        &.fixed{
            position: fixed;
        }
    }
}

#logo {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../assets/img/aconcagua-logo.png');
    width: 168px;
    height: 35px;
    background-size: 168px 35px;
    display: inline-block;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
    margin-bottom: 11px;
    &:hover{ opacity: .93;}
    @media only screen and (max-width:480px) {
        background-position: left;
        width: 39px;
    }
}

.menu {
    list-style: none;
    list-style-position: inside;
    padding: 0;
    li {
        display: inline-block;
        a {
            display: inline-block;
            font-weight: 300;
            color: #8E8E8E;
            font-size: 18px;
            line-height: 24px;
            text-decoration: none;
            padding: 5px 0;
            border-bottom: 1px solid #fff;
            @media only screen and (max-width:768px) {
                padding: 16px 0;
                border-bottom: 1px solid #1A963A;
            }
            &:hover, .activo {
                border-bottom: 1px solid #1A963A;
            }
        }
        &:last-of-type{
            a{
                border-bottom: none;
            }
        }
    }
}

#desktop-menu{
    float: right;
    @media only screen and (max-width:768px) {
        display: none;
    }
    li{
        margin-left: 20px;
    }
}

#mobile-menu{
    float: left;
    padding-top: 5px;
    display: none;
    width: 100%;
    li{
        display: block;
        a{
            display: block;
        }
    }
}

#hamburger{
    text-decoration: none;
    background-size: 22px 16px;
    width: 22px;
    height: 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../assets/img/hamburger.png');
    float: right;
    display: none;
    @media only screen and (max-width:768px) {
        display: inline-block;
    }
}

.homepage #buscador-menu{ display: none;}
#buscador-menu{
    text-decoration: none;
    display: inline-block;
    width: 20px;
    height: 34px;
    background-image: url('../assets/img/lupa.png');
    background-size: 20px 20px;
    float: right;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
    margin-left: 15px;
}