﻿#noticias-widget {
    padding: 3.8% 0 60px;

    @media handheld, only screen and (max-width: 768px), only screen and (max-device-width: 768px) and (orientation:portrait) {
        .c5, .c7 {
            width: 50%;
        }
    }

    @media only screen and (max-width:560px) {
        .c5, .c7 {
            width: 100%;
        }
    }

    .noticias-post {
        display: block;
        position: relative;
        text-decoration: none;
        border-bottom: 1px solid rgba(92, 92, 92, 0.3);
        padding-bottom: 20px;
        margin-bottom: 20px;
        -o-transition:.5s;
        -ms-transition:.5s;
        -moz-transition:.5s;
        -webkit-transition:.5s;
        transition:.5s;
        &:hover{
           div{
            background-color: rgba(239, 249, 238, 1);
           } 
        }

        @media handheld, only screen and (max-width: 768px), only screen and (max-device-width: 768px) and (orientation:portrait) {
            margin-bottom: 20px;
        }

        &.destacada {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
            border-radius: 3px;
            @media handheld, only screen and (max-width: 560px), only screen and (max-device-width: 560px) and (orientation:portrait) {
                margin-bottom: 15px;
            }
        }

        h3 {
            font-size: 28px;
            line-height: 38px;
            font-weight: 300;
            color: #1A963A;
            @media only screen and (max-width:560px) {
                font-size: 24px;
                line-height: 33px;
            }
        }

        h4 {
            font-size: 18px;
            line-height: 24px;
            font-weight: 300;
            color: #767676;
        }

        p {
            color: 14px;
            line-height: 19px;
            color: #767676;
            font-weight: 300;
        }

        img {
            border-radius: 3px;
        }

        div {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            background-color: rgba(239, 249, 238, 0.9);
            border-radius: 3px 3px 0 0;
            padding: 3% 20px;
            -o-transition:.5s;
            -ms-transition:.5s;
            -moz-transition:.5s;
            -webkit-transition:.5s;
            transition:.5s;
            @media only screen and (max-width:560px) {
                top: inherit;
                bottom: 0;
                padding: 10px;
            }
        }
    }
}
