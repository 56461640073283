﻿//filtros-avanzados
#modal-filtros{
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 3;
    .singlefilter{
        width: 320px;
    }
    .doublefilter{
        width: 640px;
        @media only screen and (max-width:640px){
            width: 320px;
        }
        .modal-row{
            width: 50%;
            @media only screen and (max-width:640px){
                width: 100%;
            }
        }
    }
    .modal-row .c6{
        width: 50%;
        @media only screen and (max-width:768px){
            width: 50%;
            &:first-of-type{
                padding-right: 10px;
            }
            &:last-of-type{
                padding-left: 10px;
            }
        }
    }
}

.modal-filtros {
    width: 620px;
    background-color: #FFFFFF;
    border: 1px solid #E9E9E9;
    border-top: none;
    border-radius: 0 0 2px 2px;
    @media only screen and (max-width:768px){
        width: auto;
    }
    .filtro {
        padding-top: 15px;
        padding-bottom: 15px;
        @media only screen and (max-width:480px){
            padding-top: 1px;
            padding-bottom: 1px;
        }
    }

    p {
        font-size: 18px;
        line-height: 24px;
        font-weight: 300;
        color: #5C5C5C;
        padding-bottom: 10px;
        @media only screen and (max-width:480px){
            padding-bottom: 5px;
        }
    }
    .limpiar-filtro{
        font-size: 16px;
        color: #8E8E8E;
        display: inline-block;
        line-height: 2;
        text-decoration: none;
        padding-top: 5px;
        &:hover{
            text-decoration: underline;
        }
        @media only screen and (max-width:480px){
            padding-top: 0;
        }
    }

    > .row {
        border-bottom: 1px solid #E9E9E9;

        &:last-child {
            border: none;
        }
    }

    .filtro .c6 {
        &:first-of-type {
            padding-right: 10px;
            @media only screen and (max-width:768px){
                padding-right: 20px;
                margin-bottom: 20px;
            }
            @media only screen and (max-width:768px){
                margin-bottom: 0;
            }
        }
        &:last-of-type {
            padding-left: 10px;
            @media only screen and (max-width:768px){
                padding-left: 20px;
            }
        }
    }

    input[type='text'] {
        display: inline-block;
        border: 3px solid #8DC703;
        border-radius: 3px;
        background-color: #F6F6F6;
        font-size: 18px;
        line-height: 24px;
        padding: 10px 8px;
        color: #888888;
        width: 100%;
        @media only screen and (max-width:480px){
            padding: 5px;
        }
    }

}

